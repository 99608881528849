/**
 * This file is used to hydrate state from the server. It is used in the server-rendered HTML to pass the state to the client.
 * Currently we embed the following fields as <meta> tags in the HTML:
 * - Facility => ID, Name, Logo, URLs (Follow UP, Food Menu, Patient Portal)
 * - Visit => Patient First Name
 */

/**
 * Get the content from a meta tag with the name `vital-response:${key}`
 */
const getContentFromMeta = ({ document }: Window, key: string) => {
  const tagContent = document.querySelector<HTMLMetaElement>(`meta[name="vital-response:${key}"]`)?.content

  // Return null if the query selector doesn't find a .content value
  if (!tagContent) {
    return null
  }

  return decodeURIComponent(tagContent)
}

// Tests for getContentFromMeta
if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest

  describe('getContentFromMeta', () => {
    it('returns null if the element is not present', () => {
      const fakeDocumentObject = {
        document: { querySelector: () => undefined },
      } as unknown as Window

      expect(getContentFromMeta(fakeDocumentObject, 'facility-id')).toBeNull()
    })

    it('returns decoded content', () => {
      const fakeDocumentObject = {
        document: {
          querySelector: () => ({ content: 'Greenwood%20Hospital' }),
        },
      } as unknown as Window

      expect(getContentFromMeta(fakeDocumentObject, 'facility-id')).toEqual('Greenwood Hospital')
    })

    it('returns "true" as a string for a set feature flag', () => {
      const fakeDocumentObject = {
        document: { querySelector: () => ({ content: 'true' }) },
      } as unknown as Window

      expect(getContentFromMeta(fakeDocumentObject, 'facility-id')).toEqual('true')
    })
  })
}

/**
 * Get the facility's ID, Name, and Logo from the meta tags.
 */
export const facility =
  import.meta.env.MODE === 'test'
    ? {
        id: 'acme',
        logo: 'https://ucarecdn.com/ab32df73-bb39-4ee1-be72-c0153461d15a/',
        name: 'Greenwood Hospital',
        url: {
          followUp: 'https://example.com/follow-up',
          foodMenu: 'https://example.com/food-menu',
          patientPortal: 'https://example.com/patient-portal',
        },
      }
    : {
        id: getContentFromMeta(window, 'facility-id'),
        logo: getContentFromMeta(window, 'facility-logo'),
        name: getContentFromMeta(window, 'facility-name'),
        url: {
          followUp: getContentFromMeta(window, 'facility-followUpUrl'),
          foodMenu: getContentFromMeta(window, 'facility-foodMenuUrl'),
          patientPortal: getContentFromMeta(window, 'facility-patientPortalUrl'),
        },
      }

/**
 * Get the patient's first name from the meta tag.
 * - Defaults to `'Te Riu'` in test mode.
 */
export const patientFirstName = import.meta.env.MODE === 'test' ? 'Te Riu' : getContentFromMeta(window, 'first-name')
