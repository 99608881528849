import { facility } from './hydrated-state'

const { VITE_ENVIRONMENT, VITE_VERSION } = import.meta.env

const sharedDatadogConfig = {
  clientToken: 'pub0bcc53e0713b5d639caa62bb255dbf30',
  site: 'datadoghq.com',
  service: 'patient',
  env: VITE_ENVIRONMENT,
  version: VITE_VERSION,
  sessionSampleRate: 100,
  storeContextsAcrossPages: true,
}

/**
 * Returns a timestamp string in a "HH:MM:SS" format.
 */
function getTimestamp(): string {
  const now = new Date()

  return [now.getHours(), now.getMinutes(), now.getSeconds()]
    .map(String)
    .map((chunk) => chunk.slice(0, 2))
    .map((chunk) => chunk.padStart(2, '0'))
    .join(':')
}

enum StatusCodeColor {
  Debug = 'inherit', // Use the default color for Debug
  Error = '#E95F5D',
  Info = '#89cff0',
  Warning = '#F0BB4B',
}

/**
 * Returns a HEX color for a given response status code number.
 */
function getStatusCodeColor(status: 'debug' | 'error' | 'info' | 'warn'): StatusCodeColor {
  if (status === 'debug') {
    return StatusCodeColor.Debug
  }

  if (status === 'error') {
    return StatusCodeColor.Error
  }

  if (status === 'info') {
    return StatusCodeColor.Info
  }

  return StatusCodeColor.Warning
}

export const initInstrumentation = async () => {
  if (VITE_ENVIRONMENT === 'production') {
    const { datadogRum } = await import('@datadog/browser-rum')

    datadogRum.init({
      ...sharedDatadogConfig,
      applicationId: 'c870695b-aa81-4b3a-954e-ae4f244a2a64',
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      workerUrl: new URL('@datadog/browser-worker', import.meta.url).href,
      defaultPrivacyLevel: 'mask-user-input',
      compressIntakeRequests: true,
    })

    // Set Facility ID
    datadogRum.setGlobalContextProperty('facilityId', facility.id)
  }

  const { datadogLogs } = await import('@datadog/browser-logs')

  datadogLogs.init({
    ...sharedDatadogConfig,
    forwardErrorsToLogs: true,
    beforeSend: (log) => {
      // Filter out "TypeError: Load failed" logs, where the status code is 0
      // This is caused when a user navigates and the browser cancels the fetch request
      if (log.http?.status_code === 0) {
        return false
      }

      // Filter out logs caused by dynamic import/fetch/Apollo when we roll out a new version of the app,
      // which forces a refresh of the page
      if (log.view.url.includes('version_reload=true')) {
        if (
          log.message.includes('The string did not match the expected pattern.') ||
          log.message.includes('Importing a module script failed.') ||
          log.message.includes('Load failed') ||
          log.message.includes('Failed to fetch') ||
          log.message.includes('NetworkError when attempting to fetch resource.')
        ) {
          return false
        }
      }

      // Filter out "Script error" logs, which are caused by cross-origin errors, likely for browser extensions
      if (log.message.includes('Uncaught "Script error."')) {
        return false
      }

      if (['Fetch error POST', 'XHR error POST'].some((ignoredLog) => log.message.includes(ignoredLog))) {
        return false
      }

      // In non-production environments, log to the console instead of Datadog
      if (VITE_ENVIRONMENT !== 'production') {
        console.groupCollapsed(
          `[DATADOG] ${getTimestamp()} \`${log.message}\` (%c${log.status.toUpperCase()}%c)`,
          `color:${getStatusCodeColor(log.status)};`,
          'color:inherit;',
        )
        console.log('Payload', log)
        console.groupEnd()

        return false
      }

      return true
    },
  })

  datadogLogs.onReady(() => {
    // In non-production environments, log to the console instead of Datadog
    if (VITE_ENVIRONMENT !== 'production') {
      console.groupCollapsed(
        '%c[DATADOG] Logs being intercepted for non-production environments',
        'color:yellow;font-weight:bold;',
      )
      console.log('Change in the `src/core-web/instrumentation.ts` file if needed')
      console.groupEnd()
    }
  })

  // Set Facility ID
  datadogLogs.setUserProperty('facilityId', facility.id)
}
